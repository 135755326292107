import $ from 'jquery';
import Rellax from 'rellax';
import Swiper from 'swiper/bundle';

$(function() {

  function homecode() {
    // ✅ DEFINITION UNSERER ELEMENTE
    const SVGLOGO = document.getElementById('logo');

    // ✅ DEFINITION VON VARIABLEN
    const LOGOLENGTH = SVGLOGO.getTotalLength();


    const SVGTOP = window.pageYOffset + document.getElementById('ce-svg-animation').getBoundingClientRect().top - 200;

    // ✅ GRUNDLEGENDES SETTING
    SVGLOGO.style.strokeDasharray = LOGOLENGTH;
    SVGLOGO.style.strokeDashoffset = LOGOLENGTH;

    // ✒️ ZEICHNEN UNSERES SVG GRAFEN
    const drawWhenScroll = () => {
      const DRAWLOGO = LOGOLENGTH * calcScrollPercent();
      SVGLOGO.style.strokeDashoffset = LOGOLENGTH - DRAWLOGO;

      if (calcScrollPercent() === 1) {
        $('.svg-container-inner').addClass('show')
      } else {
        SVGLOGO.style.fill = 'transparent';
        $('.svg-container-inner').removeClass('show')
      }
    }

    // 🙉 FUNKTION UM EINEN % WERT DES SCROLLS ZU BERECHNEN
    const calcScrollPercent = () => {
      let height = document.getElementById('ce-svg-animation').clientHeight / 2 - 300;
      let currentScroll = document.documentElement.scrollTop - SVGTOP;
      const checkpositive = Math.sign(currentScroll);
      const finalscale = currentScroll / height;
      if (checkpositive > 0) {
        if (finalscale > 1) {
          return 1;
        } else {
          return finalscale;
        }
      } else {
        return 0;
      }

    }

    // 🙊 FUNKTION FÜR DAS TRIGGERS DES SCROLL EVENTS
    window.addEventListener('scroll', drawWhenScroll);

  }

  $(window).scroll(function () {
    var scr = $(window).scrollTop();
    if (scr > 600) {
      $('body').addClass('nav-bg-in')
    } else {
      $('body').removeClass('nav-bg-in')
    }
  });


  var lastScrollTop; // This Varibale will store the top position

  window.addEventListener('scroll', function () {
    //on every scroll this funtion will be called

    //var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    var scrollTop = window.scrollY || document.documentElement.scrollTop;
    //This line will get the location on scroll
    if (scrollTop > 300) {
      if (scrollTop > lastScrollTop) { //if it will be greater than the previous

        $('body').addClass('nav-up')
        //set the value to the negetive of height of navbar 
      } else {
        $('body').removeClass('nav-up')
      }
    }

    lastScrollTop = scrollTop; //New Position Stored
  });


  // Scroll to Red Effect
  function redbgcode() {
  const triggerdiv = window.pageYOffset + document.getElementById('red-trigger').getBoundingClientRect().top;

  const redbg = () => {
    let triggerscroll = document.documentElement.scrollTop;
    console.log(triggerdiv);
    console.log(triggerscroll);
    if (triggerscroll > triggerdiv) {
      $('body').addClass('bg-red')
    } else {
      $('body').removeClass('bg-red')
    }
  }

  window.addEventListener('scroll', redbg);
  }

  var rellax_logo = new Rellax('.home-intro-overlay-logo');

  var rellax = new Rellax('.parallax-img');



  /* Mobile Menu
   **************************************************************/

  /* Add class when Menu is open for Burger Animation */

  $('.third-button').on('click', function () {
    $('.animated-icon3').toggleClass('open');
  });

  /* Open mobile Menu with Burger */

  $('.third-button').click(function () {
    $('.menu-open').fadeToggle(300)
    $('html').toggleClass('nav-open')
  })

  // let offsetTopscroll = $('.ce-scroll-container').getBoundingClientRect().top;
  // let offsetheightscroll = $('.ce-scroll-container').offsetHeight + offsetTop;

  // $(window).scroll(function () {
  //   var scr = $(window).scrollTop();
  //   if (scr > offsetTopscroll && scr < offsetheightscroll) {
  //     $('body').addClass('bg-grey')
  //   } else {
  //     $('body').removeClass('bg-grey')
  //   }
  // });

  /* Add Class on Body to trigger intitial Home Animation
   **************************************************************/

  $('body').addClass('loaded');


/*   var swiper = new Swiper(".click-swiper ", {
    simulateTouch: true,
    slideToClickedSlide: true,
    spaceBetween: 30,
    effect: "fade",
    crossfade: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }); */



  $('body').append('<div class="custom-cursor" id="customCursor"></div>');

  const swiper = new Swiper('.click-swiper', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    effect: "fade",
    crossfade: true,
    simulateTouch: true,
    slideToClickedSlide: true,

    pagination: false,
    navigation: false,
    scrollbar: false,
});

// Add click event listener to each slide
document.querySelectorAll('.click-swiper').forEach(slide => {
  slide.addEventListener('click', () => {
      swiper.slideNext();
  });
});

// Custom cursor logic
const customCursor = document.getElementById('customCursor');
const swiperContainer = document.querySelector('.click-swiper');

if( swiperContainer && customCursor ) {
  swiperContainer.addEventListener('mouseover', () => {
      customCursor.style.opacity = 1;
      document.body.style.cursor = 'none'; // Hide default cursor
  });

  swiperContainer.addEventListener('mouseout', () => {
    customCursor.style.opacity = 0;
      document.body.style.cursor = 'default'; // Show default cursor
  });

  document.addEventListener('mousemove', (e) => {
    customCursor.style.left = e.pageX + 'px';
    customCursor.style.top = e.pageY + 'px';
  });
}




/*   $('.ce-hero .swiper-container').each(function () {
    var t = $(this),
        s;

    if (t.find('.swiper-slide').length > 1) {
        s = new Swiper(this, {
            loop: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            autoHeight: true,
            speed: 800,
            observer: true,
            observeParents: true,
            autoplay: {
                delay: 5000
            },
            navigation: {
                nextEl: t.find('.swiper-button-next')[0],
                prevEl: t.find('.swiper-button-prev')[0],
            },
            pagination: {
                el: t.find('.swiper-pagination')[0],
                type: 'bullets',
                dynamicBullets: true,
            },
        });
    } else {
        t.parent().addClass('single-slide');
    }

}); */


  const swiperlarge = new Swiper(".swiper-large", {
    slidesPerView: "auto",
    simulateTouch: true,
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });


/*   $(window).on("load", function () {
    if ($("html").hasClass("is-home")) {
      homecode();
      redbgcode();
    }
  }); */

  if ($("html").hasClass("is-home")) {
    homecode();
    redbgcode();
  }



});